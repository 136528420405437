import React from 'react';
import ContactForm from '../common/ContactForm';
const Contact = (props) => {
    
    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    //     phone: '',
    //     company_name: '',
    //     message: '',
    // });

    // // const [geoData, setGeoData] = useState({});
    // const [latitude, setLatitude] = useState(null);
    // const [longitude, setLongitude] = useState(null);
    // const [ipAddress, setIpAddress] = useState(null);

    // const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY;

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [name]: value,
    //     }));
    // };

    // useEffect(() => {
    //     // Check if the Geolocation API is supported
    //     if ("geolocation" in navigator) {
    //       // Get the current location
    //       navigator.geolocation.getCurrentPosition(function(position) {
    
    //         setLatitude(position.coords.latitude);
    //         setLongitude(position.coords.longitude);
            
    //       }, function(error) {
    //         console.error("Error getting location:", error.message);
    //       });

    //     } else {
    //       console.error("Geolocation is not supported by this browser.");
    //     }

    // }, []);

    // useEffect(() => {
    //     const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${process.env.REACT_APP_PRODUCTION_BACKEND_API_TOKEN}` };
    //     try {
    //       fetch(`${process.env.REACT_APP_PRODUCTION_BACKEND_API_URL}/api/getIp`, { headers })
    //           .then(response => response.json())
    //           .then(data => setIpAddress(data.ip));
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    // }, []);

    // const HandleSubmit = async (e) => {
    //     e.preventDefault();

    //     if(formData.name === '' || formData.email === '' || formData.phone === '' || formData.company_name === '' || formData.message === ''){
    //         swal({
    //             text: "Please fill all required fields.",
    //             icon: "info",
    //         }).then( ok => {
    //         })

    //         return false;
    //     }

    //     try {
    //         const response = await fetch(
    //             `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}&pretty=1`
    //         );

    //         if (!response.ok) {
    //             throw new Error(`Error: ${response.status} - ${response.statusText}`);
    //         }

    //         const geo_data = await response.json();
    //         // setGeoData(geo_data.results[0].components);

    //         const data = JSON.stringify({
    //             "data": {
    //                 "name": formData.name,
    //                 "email": formData.email,
    //                 "phone": formData.phone,
    //                 "company_name": formData.company_name,
    //                 "message": formData.message,
    //                 "ip_address": ipAddress,
    //                 "lat": latitude,
    //                 "long": longitude,
    //                 "country": geo_data.results[0].components.country,
    //                 "state": geo_data.results[0].components.state,
    //                 "city": geo_data.results[0].components.state_district,
    //             }
    //         })

    //         // console.log(geoData);
    //         // console.log(data);

    //         const status = SentMail(data,process.env.REACT_APP_CONTACT_FORM_API,e);

    //         if(status){
    //             setFormData({
    //                 name: '',
    //                 email: '',
    //                 phone: '',
    //                 company_name: '',
    //                 message: '',
    //             });
    //         }

            
    //         } catch (error) {
    //         console.error('Error fetching location details:', error);
    //     }

    // };

  return (
    <>
    <section className='contact-block'>
        <div className="container py-5 px-lg-5" id="contact">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="text-primary-gradient fw-medium">Let’s Connect</h5>
                {props.heading ? 
                    <h3 className="contact-head mb-3">{props.heading}</h3>
                    : 
                    <h3 className="contact-head mb-5">Get In Touch!</h3>
                }
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="wow fadeInUp" data-wow-delay="0.3s">
                        <p className="text-center mb-4">We are right here to help you get your personalized WebRTC Softphone application.</p>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Contact