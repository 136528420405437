import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
    <section className='privacy-policy-block'>
        <div className='hero-header'>
            <div className='container px-lg-5'>
                <div className='row g-5'>
                    <div className='col-lg-8 text-center text-lg-start'>
                        <h1 className='text-white mb-4 animated slideInDown'>Privacy Policy</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='content-wr'>
            <div className='container px-lg-5'>
                <div className="content">
                    <p>For many years, respecting and protecting client privacy has been vital to our business. We trust that, by reviewing this Privacy Policy, our clients will better understand how we keep your personally identifiable information confidential while using it to provide services and products. Be advised, however, that additional privacy rights, principles or policies, specific to your country of residence and normally guaranteed by your government, may not always apply to you as the Fonimo user due to the limited scope of organizations that have actual jurisdiction over the business activities of Fonimo. We will not disclose or transfer any information that is provided through this website to any company — even to our own affiliates or agents or information providers. We may, however, be requested to disclose such information following a valid written request from a law enforcement agency.</p>
                    <h3>Absolutely, unconditionally and positively no spam</h3>
                    <p>Fonimo guarantees that submission of email address will not result in excessive and unwanted email contacts. Your email address data stays with Fonimo ONLY and we unconditionally guarantee that no other company, partner or even our own affiliate will ever be able to access and/ or use that information for such purposes. You may, however, receive occasional service updates and announcements; we reasonably limit the number of such emails. Service notifications related to billing, expiring services, account balance and other billing/ technical aspects of your subscription are excluded from this policy.</p>
                    <h3>Security</h3>
                    <p>We will save your play store account email address in a safe place. It will not be shared with any other person or a third party.</p>
                    <h3>How we protect client information</h3>
                    <p>We take our responsibility to protect the privacy and confidentiality of any client information very seriously. We maintain physical, electronic and procedural safeguards that comply with applicable legal standards to store and secure client information from unauthorized access and use, alteration and destruction. Our own policies and procedures have been developed to protect the confidentiality of client information and to help achieve compliance with relevant rules and regulations. We hold our employees accountable for complying with those policies, procedures, rules and regulations.</p>
                    <p>
                        <b>We collect, use and maintain client information only for appropriate purposes.</b>
                    </p>
                    <p>We limit the collection, use and retention of client information to what we reasonably believe will help us deliver the service, to administer our business, to market our services and products, and to meet applicable laws and regulations.</p>
                    <p>
                        <b>We review your personal data only when we believe it will enhance the services and products we can provide to clients or for legal and routine business reasons. All other access to your personal information is restricted to valid requests from law enforcement agencies.</b>
                    </p>
                    <p>We do not share client information with anyone, even among our own affiliates and business units. We do share your identification data with vendors who are legally required to keep ID copies of clients due to regulations in their respected countries. We may disclose information we have about clients as required by law. For example, we may share information with regulatory authorities and law enforcement officials who have jurisdiction over us or if we are required to do so by applicable law and to provide information to protect against fraud.</p>
                    <p>
                        <b>We comply with local and international anti-terrorist and anti-money laundering regulations.</b>
                    </p>
                    <p>To help the government fight the funding of terrorism and money laundering activities, the law requires us to obtain, verify, and record any information that identifies each person who opens an account. Requirements may vary, however, any information we receive from you may be used for that purpose. When you open an account, we will ask for your name, email address, and sometimes physical address, as well as other information that will allow us to identify you. Under certain circumstances, we may also ask to see copies or scans of your identifying documents.</p>
                    <p>
                        <b>Information Collected while Using the Application</b>
                    </p>
                    <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
                    <ul>
                        <li>Information regarding your location</li>
                        <li>Information from your Device’s phone book (contacts list)</li>
                    </ul>
                    <p>We use this information to provide features of Our Service, to improve and customise Our Service. The information may be uploaded to the Company’s server or it may be simply stored on Your device.</p>
                    <h3>Use of contact information</h3>
                    <ul>
                        <li>We are fetching contact information from the device and edit contact from our application.&nbsp;</li>
                        <li>We also allow user to delete contact from the application.</li>
                        <li>We are using all contact information in offline mode only so we are not sending any information to cloud.</li>
                        <li>Contact information is not shared with anyone else so we are keeping privacy of users contact information.</li>
                        <li>Contact information is safe and secure while using this application.</li>
                        <li>We are asking prior permission on start of the application for accessing contact information so we are using contact information after getting users confirmation only.</li>
                        <li>You can enable or disable access to this information at any time, through Your Device settings.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PrivacyPolicy