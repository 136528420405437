import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../components/common/Misc';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import Meta from '../components/common/Meta.js';
import Sidebar from '../components/common/Sidebar';
import rehypeRaw from 'rehype-raw';
const Single = () => {

  const { slug } = useParams();

  const [response, setResponse] = useState(null);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        fetch(`${BackendURL}/api/posts/${slug}?populate[meta][populate]=*&populate[featured_image]=*&populate=*`, { headers })
            .then(response => response.json())
            .then(data => setResponse(data.data));
    }, [slug]);

    console.log(response);

  return (
    <>
    {response && response?.attributes?.meta ?
        <Meta value={response?.attributes} canonical={`https://fonimo.app/blog/${response?.attributes?.slug}`} />
    : ""}
    {response ?
        <main>
        <section className='blogs-block'>
            <div className='blogs-inner-wr hero-header'>
                <div className='container px-lg-5'>
                    <div className='row g-5 justify-content-center'>
                        <div className='col-lg-10 text-center '>
                            <h1 className='text-white mb-4 animated slideInDown'>{response.attributes.title}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blog-content py-5'>
                <div className='container px-lg-5'>
                    <div className='blog-content-inner-wr'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-8 col-12 mb-4'>
                                <figure className='mb-4'>
                                    <img className='img-fluid' alt={response.attributes.featured_image.data.attributes.name.replace(/\.[^/.]+$/, "")} src={BackendURL+response.attributes.featured_image.data.attributes.url}  />
                                </figure>
                                <article>
                                    <div className='content'>
                                    <Markdown rehypePlugins={[rehypeRaw]}>{response.attributes.content}</Markdown>
                                    </div>
                                </article>
                            </div>
                            <div className='col-lg-4 col-12 ps-5 sidebar-wr'>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
    : ""}</>
  )
}

export default Single