import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsent = () => {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('cookieConsent');
        if (consent === 'true') {
            setConsentGiven(true);
        }
    }, []);

    const handleConsent = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 });
        setConsentGiven(true);
    };

    if (consentGiven) {
        return null; // No need to show the consent banner once consent is given
    }

    return (
        <div className="cookie-consent">
            <p>This website uses cookies to ensure you get the best experience.</p>
            <button onClick={handleConsent}>Accept</button>
        </div>
    );
};

export default CookieConsent;
