import React from 'react';
import {Helmet} from "react-helmet";
import { BackendURL } from './Misc';

const Meta = (props) => {

    const response = props?.value;
    const canonical = props?.canonical;
  return (
    <>
    <div className="application">
          <Helmet>
            {canonical
            ? 
              <link rel='canonical' href={canonical} />
            : ""
            }
            <meta charSet="utf-8" />
            {response?.meta?.meta_title ? 
              <title>{response?.meta?.meta_title}</title>
            :
              <title>{response?.title}</title>
            }
            {response?.meta?.meta_desc ?
              <meta name="description" content={response?.meta?.meta_desc} ></meta>
            :
              <meta name="description" content={response?.content[0]?.children[0]?.text} ></meta>
            }
            {response?.meta?.meta_title ? 
              <meta property="og:title" content={response?.meta?.meta_title} className="seo-meta-tag" />
            :
              <meta property="og:title" content={response?.title} className="seo-meta-tag" />
            }
            {response?.meta?.meta_desc ?
              <meta property="og:description" content={response?.meta?.meta_desc} className="seo-meta-tag" />
            :
              <meta property="og:description" content={response?.content[0]?.children[0]?.text} className="seo-meta-tag" />
            }
            {response?.meta?.meta_keywords ?
              <meta property="og:keywords" content={response?.meta?.meta_keywords} className="seo-meta-tag" />
            :
              ""
            }
            {response?.meta?.meta_image?.data ?
              <meta property="og:image" content={BackendURL+response?.meta?.meta_image?.data?.url} className="seo-meta-tag" />
            :
              ""
            }
          </Helmet>
        </div>
    </>
  )
}

export default Meta