import React, {useState, useEffect} from 'react';
import { BackendURL, API_TOKEN } from '../common/Misc';

const Navbar = () => {

  const [globalData, setGlobalData] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  // Static offer end date in UTC+12:00 timezone
  const staticEndDate = new Date('2024-11-29T23:59:00+12:00').toISOString();

  const calculateTimeLeft = (endDate) => {
    const now = new Date();
    const difference = +new Date(endDate) - +now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };
  
  useEffect(() => {
    // Set the countdown to the static end date
    const timer = setInterval(() => {
      const timeLeftNow = calculateTimeLeft(staticEndDate);
      setTimeLeft(timeLeftNow);
    }, 1000);
  });

  var menu_id = '';

  const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };

  useEffect(() => {
    fetch(BackendURL+process.env.REACT_APP_GLOBAL_API, { headers })
        .then(response => response.json())
        .then(data => setGlobalData(data.data));      
  }, []);

  if(globalData != null){
    menu_id = globalData.attributes.menu.data.id;
  }
  
  useEffect(() => {
        fetch(`${BackendURL}/api/menus/${menu_id}?populate=*`, { headers })
        .then(response => response.json())
        .then(data => setMenuData(data.data));        
  }, [menu_id]);

    
  return (
    <>
      {/* <div className='top-header p-2'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-10'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-lg-6 text-center'>
                  <p className='m-0 text-white' style={{ineHeight: "20px"}}>We are at ITEXPO | February 11-13, 2025 | Fort Lauderdale, Florida, USA</p>
                </div>
                
                <div className='col-lg-3 my-1 my-md-0'>
                  <div style={{textAlign: 'center'}}>
                    <h5 className='text-white' style={{display:'inline-block',verticalAlign: 'middle', margin: 0, marginRight:'15px', fontSize: "17px", lineHeight: "22px" }}>Offer ends in:</h5>
                    <h4 className='text-white' style={{display:'inline-block',verticalAlign: 'middle', margin: 0, fontSize: "17px", lineHeight: "22px" }}>
                      {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                    </h4>
                  </div>
                </div>
                
                <div className='col-lg-2 text-center mt-1 mt-md-0'>
                  <a href='/schedule-a-demo' className='btn btn-secondary-gradient rounded-pill py-2 px-4'>Meet Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>     */}
      <header id='header-main'>
        <div className="container">
        {globalData && menuData ?
          <div className='main-header'>
            {/* <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <a href="/" className="navbar-brand p-0">
                    <img width={164} height={45} className="logo" src={BackendURL+globalData.attributes.primary_logo.data.attributes.url} alt="Logo" />
                    <img width={164} height={45} className="sticky-logo" src={BackendURL+globalData.attributes.secondary_logo.data.attributes.url} alt="Logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav mx-auto py-0">
                      <ul id="header-menu" className="menu">
                        <li className="menu-item childrens">
                            <a href="#" className="nav-item nav-link">Solutions</a>
                            <ul className="sub-menu">
                              <li className="menu-item"><a href="/white-label-softphone">White Label Softphone</a></li>
                              <li className="menu-item"><a href="/custom-voip-softphone">Custom Softphone</a></li>
                            </ul>
                        </li>
                        <li className="menu-item">
                          <a href="/partners" className="nav-item nav-link">Partners</a>
                        </li>
                        <li className="menu-item">
                          <a href="/roadmap" className="nav-item nav-link">Roadmap</a>
                        </li>
                        <li className="menu-item">
                          <a href="/blog" className="nav-item nav-link">Blogs</a>
                        </li>
                      </ul>
                    </div>
                    
                    {menuData.map(({attributes,id}) => {
                      return (<div className="navbar-nav mx-auto py-0" key={id}> <ul id='header-menu' className='menu'>
                      {attributes.links.map((item) => (
                        <li className='menu-item'><a key={item.id} href={`/${item.url}`} className="nav-item nav-link">{item.label}</a></li>
                      ))}
                      <a href="/blog" className="nav-item nav-link">Blog</a>
                      </ul></div>)
                    })}
                    
                    <a href={globalData.attributes.header_cta.url} id="contact-btn" className="btn btn-secondary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">{globalData.attributes.header_cta.label}</a>
                </div>
            </nav> */}

            <nav className="navbar navbar-expand-lg navbar-light bg-body-tertiary py-4">
              <div className="container-fluid">
                <a href="/" className="navbar-brand p-0">
                    <img width={164} height={45} className="logo" src={BackendURL+globalData.attributes.primary_logo.data.attributes.url} alt="Logo" />
                    <img width={164} height={45} className="sticky-logo" src={BackendURL+globalData.attributes.secondary_logo.data.attributes.url} alt="Logo" />
                </a>
                <div className='navbar-toggler-button'>
                  <a href={globalData.attributes.header_cta.url} target={globalData.attributes.header_cta.target} className="btn btn-primary-gradient rounded-pill py-2 px-4 me-3 d-inline-block d-lg-none">{globalData.attributes.header_cta.label}</a>
                  <button className="navbar-toggler d-inline-block" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className='navbar-nav mx-auto py-0'>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Solutions
                        </a>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="/white-label-softphone">White Label Softphone</a></li>
                          <li><a className="dropdown-item" href="/custom-voip-softphone">Custom Softphone</a></li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/partners">Partners</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/roadmap">Roadmap</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/blog">Blogs</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/pricing">Pricing</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/contact-us">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
                  <a href={globalData.attributes.header_cta.url} target={globalData?.attributes?.header_cta?.target} id="contact-btn" className="btn btn-secondary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">{globalData.attributes.header_cta.label}</a>
              </div>
            </nav>
          </div>
          : ""}
        </div>
      </header>

    
    </>
  )
}

export default Navbar