import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import SentMail from '../common/SentMail';
import { BackendURL, API_TOKEN } from '../common/Misc';
import ScrollToTop from "react-scroll-to-top";
import CookieConsent from '../common/CookieConsent';
import Popup from '../common/Popup';


const Footer = () => {

    const [globalData, setGlobalData] = useState(null);
    const [menuData, setMenuData] = useState(null);
  
    var menu_id = '';
  
    const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
  
    useEffect(() => {
      fetch(BackendURL+process.env.REACT_APP_GLOBAL_API, { headers })
          .then(response => response.json())
          .then(data => setGlobalData(data.data));      
    }, []);
  
    if(globalData != null){
      menu_id = globalData.attributes.menu.data.id;
      // console.log(globalData.attributes.primary_logo.data.attributes.url);
    }
    
    useEffect(() => {
          fetch(`${BackendURL}/api/menus/${menu_id}?populate=*`, { headers })
          .then(response => response.json())
          .then(data => setMenuData(data.data));        
    }, [menu_id]);

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const HandleSubmit = async (e) => {
        e.preventDefault();

        if(formData.email === ''){
            swal({
                text: "Please fill all required fields.",
                icon: "info",
            }).then( ok => {
            })

            return false;
        }

        const data = JSON.stringify({
            "data": {
                "email": formData.email,
            }
        });

        const status = SentMail(data,process.env.REACT_APP_NEWSLETTER_API, e);

        if(status){
            setFormData({
                email: '',
            });
        }

    };

    // console.log(globalData.attributes);

  return (
    <>
    <footer id='footer-main'>
        <div className="container-fluid bg-primary text-light footer wow">
            <div className="container py-5 px-lg-5">
                {globalData ? 
                    <div className="row g-5">
                        <div className="col-md-6 col-lg-3">
                            <h4 className="text-white mb-4">India</h4>
                            {globalData.attributes.address ? 
                                <p><i className="fa fa-map-marker-alt me-3"></i>{globalData.attributes.address}</p>
                            : ""}
                            {globalData.attributes.contact_no ? 
                                <p className='row'>
                                    <div className='col-2'>
                                        <i className="fa fa-phone-alt me-3"></i>
                                    </div>
                                    <div className='col-10 p-0'>
                                        <div className='row mb-2'>
                                            {/* <div className='col-2 p-0'><img src='/img/ind-icon.png' style={{width:"35px"}} /></div> */}
                                            <div className='col-8 p-0'><span><a style={{color:"#fff"}} href={`tel:${globalData.attributes.contact_no}`}>{globalData.attributes.contact_no}</a></span></div>
                                        </div>
                                    </div>
                                </p>
                            : ""}
                            {globalData.attributes.email ? 
                                <p><i className="fa fa-envelope me-3"></i><a style={{color:"#fff"}} href={`mailto:${globalData.attributes.email}`}>{globalData.attributes.email}</a></p>
                            : ""}
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className='col-12 p-0'>
                                <h4 className="text-white mb-4">USA</h4>
                                <p><i className="fa fa-map-marker-alt me-3"></i>425 W Colonial Dr Suite 303 Orlando, Florida, United States 32804</p>
                                <p className="row">
                                <div className="col-2">
                                    <i className="fa fa-phone-alt me-3"></i>
                                </div>
                                <div className="col-10 p-0">
                                    <div className='row'>
                                        <div className='col-8 p-0'><span><a style={{color:"#fff"}} href='tel:+1 315 898 1139'>+1 315 898 1049</a></span></div>
                                    </div>
                                </div>
                                </p>
                            </div>
                            <div className='col-12 p-0 mt-4'>
                            <h4 className="text-white mb-4">UK</h4>
                                <p><i className="fa fa-map-marker-alt me-3"></i>128, City Road, London, EC1V 2NX, United Kingdom</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <h4 className="text-white mb-4">Newsletter</h4>
                            {/* {globalData.attributes.newsletter ? 
                                <p>{globalData.attributes.newsletter}</p>
                            : ""} */}
                            <div className="position-relative w-100 mt-3">
                                <form onSubmit={HandleSubmit}>
                                    <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" value={formData.email} name="email" onChange={handleChange} required type="text" placeholder="Your Email" style={{height: "48px"}} />
                                    <button type="submit" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2" ><i className="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                                </form>
                            </div>
                            <h4 className="text-white mt-4 mb-4">Popular Links</h4>
                            <a className="btn btn-link" href="/white-label-softphone">White Label Softphone</a>
                            <a className="btn btn-link" href="/custom-voip-softphone">Custom Softphone</a>
                            <a className="btn btn-link" href="/partners">Partners</a>
                            <a className="btn btn-link" href="/privacy-policy">Privacy Policy</a>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h4 className="text-white mb-4">Download</h4>
                            <a className="btn btn-link" target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.inextrix.fonimo">Android</a>
                            <a className="btn btn-link" target="_blank" rel="noreferrer" href="https://apps.apple.com/ph/app/fonimo-voip-softphone/id6471072320">iPhone</a>
                            <div className="d-flex mt-3 pt-2">
                                <a className="btn btn-outline-light btn-social" target="_blank" rel="noreferrer" href="https://x.com/fonimo_app?s=20"><i className="fab fa-x-twitter"></i></a>
                                <a className="btn btn-outline-light btn-social" target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100086481497015&mibextid=JRoKGi"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light btn-social" target="_blank" rel="noreferrer" href="https://www.instagram.com/fonimo_app?igsh=OGQ5ZDc2ODk2ZA=="><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-light btn-social" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/fonimo/"><i className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-outline-light btn-social" target="_blank" rel="noreferrer" href="https://www.youtube.com/@fonimo.Softphone"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                : ""}
            </div>
            <div className="container px-lg-5">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="border-bottom" href="/">Fonimo</a>, All Right Reserved. 
                            Product by  <a className="border-bottom" href="https://inextrix.com/">Inextrix Technologies Pvt. Ltd.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <ScrollToTop smooth />
    <CookieConsent/>
    <Popup/>
    </>
  )
}

export default Footer