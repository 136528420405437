import React from 'react'
import Meta from '../components/common/Meta'

const metaData = {
    meta : {
        meta_title: "White Label Softphone App | Best VoIP Softphone Software",
        meta_desc: "Empower your business with the ultimate VoIP softphone solution. Our white label softphone app delivers crystal-clear calls, advanced features, and seamless integration.",
        meta_keywords: "White Label Softphone, VoIP Softphone, Best Softphone Software, VoIP Softphone App",
    }
}
const Pricing = () => {
  return (
    <>
    <Meta value={metaData} canonical="https://fonimo.app/pricing" />
    <section className='comn-hero-blk'>
        <div className='comn-hero-inner'>
            <div className='container px-lg-4'>
                <div className='row justify-content-between'>
                    <div className='col-lg-6 hero-content-wr pe-lg-5'>
                        <h1 className='text-white mb-4'>WebRTC Based VoIP Softphone Pricing</h1>
                        <p className='text-white pb-4'>Discover competitive pricing for our WebRTC-based VoIP softphone solutions. Choose a plan that fits your business needs, offering high-quality communication at affordable rates.</p>
                        <a href='/contact-us' className='btn btn-secondary-gradient py-sm-3 px-4 px-sm-5'>Get In Touch</a>
                    </div>
                    <div className='col-lg-5 hero-image-wr'>
                        <figure>
                            <img src='/img/custom_voip_1.webp' alt='Custom VOIP Softphone' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='pricing-blk py-lg-5'>
        <div className='container p-lg-4'>
            <div className='pricing-inner-wr'>
                <div className='pricing-head-wr text-center'>
                    <h2 className='mb-4'>Softphone Plan Comparison Summary</h2>
                    <p className='pb-4'>Compare our Softphone plans Basic, Pro, Enterprise, and Fully Custom to find the perfect fit for your business. Discover the features each plan offers to enhance your communication strategy.</p>
                </div>
                <div className="pricing-table-wr row justify-content-center mt-5">
                    <div className="pricing-table col-9">
                        <div className="pricing-table-head row">
                            <div className="col-9">
                                <div className="pricing-head-title row">
                                    <div className="col-6 py-2">
                                        <h5> Features</h5>
                                    </div>
                                    <div className="col-2 py-2 text-center">
                                        <h5> Basic</h5>
                                    </div>
                                    <div className="col-2 py-2 text-center">
                                        <h5> Pro</h5>
                                    </div>
                                    <div className="col-2 py-2 text-center">
                                        <h5> Enterprise</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 py-2 text-center">
                                <h5> Fully Custom</h5>
                            </div>
                        </div>
                        <div className="pricing-table-content-wr row align-items-center">
                            <div className="col-9">
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Audio calls</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Recent history</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Contacts</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Push notifications</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Call recordings</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">QR code login</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Video call</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Chat (one2one chat group chat file sharing)</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Phonebook (company/private)</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">SMS</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Message broadcast</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Fax</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Meeting</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Auto Provisioning</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                                <div className="pricing-table-content row">
                                    <div className="col-6 py-2">Advance third party integration</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✘</div>
                                    <div className="col-2 py-2 text-center">✓</div>
                                </div>
                            </div>
                            <div className="col-3 py-2 text-center">
                                <div className="pricing-table-content row">
                                    <div className='col-12'>
                                        <p className='mb-0'><strong>Get in touch <br/>with us</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='plan-buttons-wr'>
                            <div className="pricing-table-content row">
                                <div className='col-12'>
                                    <div className='plan-buttons row'>
                                        <div className="col-12 py-2 text-center">
                                            <a className='btn btn-primary-gradient px-4 rounded-pill my-2' href='/contact-us'>Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='pricing-bottom-content text-center mt-5'>
                    <h4><a href='/alternative'>Find Out Why We Stand Out: Compare Us with the Competition</a></h4>
                </div> */}
            </div>
        </div>
    </section>

    <section className='download_cta_block'>
        <div className='download_cta_inner text-center p-5'  style={{borderRadius:"0"}}>
            <div className='container'>
                <h3 className='text-white mb-5'>Custom VoIP Softphone</h3>
                <p className='text-white pb-4'>Discover our Custom VoIP Softphone solutions tailored to meet your unique business needs. Get advanced features, seamless integration, and flexible options designed to enhance your communication experience.</p>
                <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Contact Us</a>
            </div>
        </div>
    </section>

    <section className="why-choose-block">
        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-5">FAQ</h3>
                </div>
                <div className="row gy-5 gx-4 justify-content-center">
                <div className="faq-wrap">
                        <div className="faq-set">
                            <h4 className='active'>Can I customize my VoIP Softphone solution? <i className="fas fa-angle-down"></i></h4>
                            <div className="content" style={{display: "block"}}>
                                <p>Yes, our Custom VoIP Softphone solutions are fully customizable to meet your unique business requirements. You can choose advanced features, integrations, and other options to create a solution tailored to your needs.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>Is there a trial period available for any of the plans? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Please contact our sales team to inquire about any available trial periods for our Softphone plans. We offer flexible options to ensure you find the right solution for your business.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>What kind of support is included with each plan? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>All our plans include customer support, with higher-tier plans offering more extensive support options. Our team is here to help you with any technical issues, setup, or customization needs.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>How can I upgrade or change my Softphone plan? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>You can upgrade or change your Softphone plan at any time. Contact our support team, and they will guide you through the process, ensuring a smooth transition to your new plan.</p>
                            </div>
                        </div>

                        <div className="faq-set">
                            <h4>How do I choose the right Softphone plan for my business? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>You can compare our Softphone plans—Basic, Pro, Enterprise, and Fully Custom using our Softphone Plan Comparison Summary. This will help you identify the features and benefits that best match your business communication needs.</p>
                            </div>
                        </div>

                </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Pricing