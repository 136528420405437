import React from 'react';
import './App.css';
import Homenew from './templates/Homenew';
// const Home = lazy(() => import('./templates/Home'));
const App = () => {
  
  return (
    <>
    <Homenew/>
    </>
  )
}

export default App