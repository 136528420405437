const BackendURL = process.env.REACT_APP_PRODUCTION_BACKEND_API_URL;
const API_TOKEN = process.env.REACT_APP_PRODUCTION_BACKEND_API_TOKEN;

const trimToWords = (str, numWords) => {
    let words = str.split(' ');
    if (words.length > numWords) {
        return words.slice(0, numWords).join(' ') + '...';
    } else {
        return str;
    }
}


export { BackendURL, API_TOKEN, trimToWords };