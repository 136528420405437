import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../common/Misc';
import swal from 'sweetalert';
import SentMail from '../common/SentMail';

const Sidebar = () => {

    let h = window.innerHeight;

    const [categories, setCategories] = useState(null);
    const [isFooterVisible, setIsFooterVisible] = useState(false);

    const isInViewport = (element) => {
        const elementTop = element.offsetTop;
        const elementBottom = elementTop + element.offsetHeight;
        const viewportTop = window.scrollY;
        const viewportBottom = viewportTop + window.innerHeight;
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    useEffect(() => {
        const handleScroll = () => {
            const footerElement = document.getElementById('footer-main');
            const footerVisible = isInViewport(footerElement);
            const screenWidth = window.innerWidth;

            console.log(window.scrollY);
            console.log(footerVisible);

            if (screenWidth >= 1240) {
                if (window.scrollY >= 300 && !footerVisible) {
                    setIsFooterVisible(true);
                } else if (footerVisible) {
                    setIsFooterVisible(false);
                } else {
                    setIsFooterVisible(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        fetch(`${BackendURL}/api/categories?populate[posts][populate]=*`, { headers })
        .then(response => response.json())
        .then(data => setCategories(data.data));
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    // const [geoData, setGeoData] = useState({});
    const [locDetails, setLocDetails] = useState(null);

    const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${process.env.REACT_APP_PRODUCTION_BACKEND_API_TOKEN}` };
        try {
          fetch(`${process.env.REACT_APP_PRODUCTION_BACKEND_API_URL}/api/getIp`, { headers })
              .then(response => response.json())
              .then(data => setLocDetails(data));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }, []);

    const HandleSubmit = async (e) => {
        e.preventDefault();

        if(formData.name === '' || formData.email === '' || formData.phone === '' || formData.message === ''){
            swal({
                text: "Please fill all required fields.",
                icon: "info",
            }).then( ok => {
            })

            return false;
        }
        
            
        if(locDetails?.locDetails?.loc){
            var latLong = locDetails?.locDetails?.loc.split(',');
        }

        const data = JSON.stringify({
            "data": {
                "name": formData.name,
                "email": formData.email,
                "phone": formData.phone,
                "message": formData.message,
                "ip_address": locDetails.ip,
                "lat": latLong[0] != "" ? latLong[0] : "",
                "long": latLong[1] != "" ? latLong[1] : "",
                "country": locDetails?.locDetails?.country ? locDetails?.locDetails?.country : "",
                "state": locDetails?.locDetails?.region ? locDetails?.locDetails?.region : "",
                "city": locDetails?.locDetails?.city ? locDetails?.locDetails?.city : "",
            }
        })

        // console.log(data);
        // console.log(locDetails);
        // console.log(latLong);

        const status = SentMail(data,process.env.REACT_APP_CONTACT_FORM_API,e);

        if(status){
            setFormData({
                name: '',
                email: '',
                phone: '',
                company_name: '',
                message: '',
            });
        }


    };

  return (
    <>
    <aside>
        {/* {categories ? 
        <div className='categories-wr'>
            <div className='categories-inner'>
                <h3 className='category-head'>All Categories</h3>
                <ul>
                {categories.map(({attributes},index) => (
                    attributes.posts.data.length !== 0 ? 
                    <li key={index} className={`${attributes.slug === slug ? "active" : ""} cat-item`} ><a href={`/category/${attributes.slug}`}>{attributes.name}</a></li>
                    : ""
                ))}
                </ul>
            </div>
        </div>
        : ""} */}
        <div className={`contact-form-wr ${isFooterVisible ? 'fixed' : ''}`} >
            <div className='form-head text-center'>
                <h3 className="mb-3">Get In Touch!</h3>
            </div>
            <form onSubmit={HandleSubmit}>
                <div className="row g-3">
                    <div className="col-12">
                        <div className="form-floating">
                            <input type="text" className="form-control" value={formData.name} id="name" name="name" placeholder="Name" onChange={handleChange} required />
                            <label htmlFor="name">Name</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-floating">
                            <input type="email" className="form-control" value={formData.email} id="email" name="email" placeholder="Email" onChange={handleChange} required />
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-floating">
                            <input type="tel" className="form-control" value={formData.phone} id="phone" name="phone" placeholder="Phone" onChange={handleChange} required />
                            <label htmlFor="phone">Phone</label>
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className="form-floating">
                            <input type="text" className="form-control" value={formData.company_name} name="company_name" id="company" placeholder="company" onChange={handleChange} required />
                            <label htmlFor="company">Company</label>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="form-floating">
                            <textarea className="form-control" value={formData.message} name="message" placeholder="Leave a message here" onChange={handleChange} id="message" style={{height: "150px"}}></textarea>
                            <label htmlFor="message">Message</label>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn btn-primary-gradient rounded-pill py-3 px-5" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </aside>
    </>
  )
}

export default Sidebar