import React, { useEffect } from 'react';
import Meta from '../components/common/Meta'

const metaData = {
    meta : {
        meta_title: "Future of WebRTC Based VoIP Softphone – Schedule a Fonimo Demo Today",
        meta_desc: "Discover the future of WebRTC-powered VoIP softphones with Fonimo. Schedule a demo today to see how seamless, secure communication can transform your business!",
    }
}

const ScheduleDemo = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup to remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
    <Meta value={metaData} canonical="https://fonimo.app/schedule-a-demo" />
    <main>
      <section className='schedule-demo-block'>
          <div className='blogs-inner-wr hero-header'>
              <div className='container px-lg-5'>
                  <div className='row g-5'>
                      <div className='col-lg-12 text-lg-start'>
                          <h1 className='text-white text-center mb-4 animated slideInDown'>Schedule a Demo</h1>
                      </div>
                  </div>
              </div>
          </div>
          <div className='calendly-form-wr'>
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/inextrix/fonimo-demo"
              style={{ minWidth: '320px', height: '700px' }}
            >
            </div>
          </div>
      </section>
    </main>
    </>
  );
};

export default ScheduleDemo;
