import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../components/common/Misc.js';
import Meta from '../components/common/Meta.js';

const Partners = () => {

    const [response, setResponse] = useState(null);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        
        fetch(`${BackendURL}/api/pages/partners?populate[meta][populate]=*&populate[Featured_Image]=*`, { headers })
            .then(response => response.json())
            .then(data => setResponse(data.data));
    }, []);

  return (
    <>
    {response && response?.attributes?.meta ?
        <Meta value={response?.attributes} canonical={`https://fonimo.app/${response?.attributes?.slug}`} />
    : ""}
    <section className='comn-hero-blk'>
        <div className='comn-hero-inner'>
            <div className='container px-lg-4'>
                <div className='row justify-content-between'>
                    <div className='col-lg-6 hero-content-wr pe-lg-5'>
                        <h1 className='text-white '>Become Our Partner </h1>
                        <h5 className='text-white mb-4'>Revenue Generation with Guaranteed Commissions!</h5>
                        <p className='text-white pb-4'>Join our partner squad by joining no commitment partnership program. Add the white label Softphone app and or custom WebRTC Softphone to expand your offering range. We assure guaranteed earnings for each account you bring with lifetime recurring commissions.</p>
                        <a href='/contact-us' className='btn btn-secondary-gradient py-sm-3 px-4 px-sm-5'>Book a Meeting</a>
                    </div>
                    <div className='col-lg-5 hero-image-wr'>
                        <figure>
                            <img src='/img/partners/partners_main.webp' alt='Partner' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='offerings-blk py-lg-4'>
        <div className='offerings-inner pt-5'>
            <div className='container px-lg-4'>
                <div className='row align-items-center'>
                    <div className='col-lg-12 pe-5 text-center partner-advantage'>
                        <h2 className='mb-4'>Major Advantages of Joining the Fonimo Partnership Program</h2>
                        <p>A custom VoIP Softphone app with white labeling and customization support is empowered with powerful features. Moreover, it is a completely customizable solution that can increase your offering range to boost revenue generation.</p>
                    </div>
                </div>
                <div className='row my-5'>

                    <div className='col-lg-6'>
                        <figure>
                            <img src='/img/Partner_1.webp' alt='Partner' />
                        </figure>
                    </div>
                    <div className='col-lg-6'>
                        <div className='row p-4 card-blk bg-grey mb-3'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/partners/product-prototyping.webp" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Lifetime Recurring Commissions</h4>
                                <p>For all referred customers, enjoy assured recurring commission for a lifetime.</p>
                            </div>
                        </div>
                        <div className='row p-4 card-blk bg-grey mb-3'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/partners/mvp-2.webp" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>Round the Clock Support</h4>
                                <p>We assure quick onboarding of clients and reliable technical support 24/7.</p>
                            </div>
                        </div>
                        <div className='row p-4 card-blk bg-grey'>
                            <div className='col-md-2 mb-3 ps-0'>
                                <img style={{width:"56px",height:"56px"}} alt="" src="/img/partners/viable-product.webp" />
                            </div>
                            <div className='col-md-10 p-0'>
                                <h4>White Label</h4>
                                <p>For eligible partners, we offer white labeling with alluring discounts and offers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='partner-program-blk pb-5'>
        <div className='container py-5'>
            <div className='partner-program-inner'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-10'>
                        <div className='section-title text-center'>
                            <h2 className='title'>Partnership Program</h2>
                            <p className='mt-3'>We have two different programs to onboard partners and let them enjoy exceptional commissions and earning opportunities: White Labeling and Reseller. Let us spill the beans for you to give you the required details about each of these programs.</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='partnership-comparison-wr'>
                            <div className="partnership-comparison"  style={{boxShadow:"0 0px 10px 2px rgba(0,0,0,0.2)",padding:"0",borderRadius:"26px"}}>
                                <div className="partnership-grid-col-header agent highlight radius-top" style={{ borderTopLeftRadius: '26px' }}>
                                    <div className="partnership-grid-col-title">Partnership Program Type</div>
                                </div>
                                <div className="partnership-grid-col-header white-label">
                                    <div className="partnership-grid-col-title">White Label</div>
                                </div>
                                <div className="partnership-grid-col-header agent highlight radius-top" style={{ borderTopRightRadius: '26px' }}>
                                    <div className="partnership-grid-col-title">Reseller</div>
                                </div>
                                <div className="partnership-grid-item" style={{ background: '#f0f8ff', color: '#000' }}>
                                    <div className="partnership-grid-row-title">App Branding</div>
                                </div>
                                <div className="partnership-grid-item white-label">
                                    <div>Get a white label Softphone app that is custom branded for you. You can choose marketing material from our increasing marketing template libraries and simply replace it with your branding. Build your local brand and digital presence while selling with your own business elements.</div>
                                </div>
                                <div className="partnership-grid-item agent highlight">
                                    <div>Become a reseller and resell our VoIP Softphone app with Fonimo branding. Use our marketing material and promote this app to receive assured lifetime referral incentives.</div>
                                </div>
                                <div className="partnership-grid-item" style={{ background: '#f0f8ff', color: '#000' }}>
                                    <div className="partnership-grid-row-title">Lead Handling</div>
                                </div>
                                <div className="partnership-grid-item white-label">
                                    <div>Your Brand. Your App. Your Strategy. Your Pricing. <br/>Own the complete process and sell your custom branded app with your own determined pricing models. Feel free to use editable marketing templates of Fonimo, if needed.</div>
                                </div>
                                <div className="partnership-grid-item agent highlight">
                                    <div>We have flexible options to work collaboratively to convert leads. Refer a new client and our sales team will manage the complete lead conversion cycle independently. Stay involved as much as you want to. Alternatively, we assure you get timely updates.</div>
                                </div>
                                <div className="partnership-grid-item" style={{ background: '#f0f8ff', color: '#000' }}>
                                    <div className="partnership-grid-row-title">Customer Relationship Management</div>
                                </div>
                                <div className="partnership-grid-item white-label">
                                    <div>You own clients, processes, billing, and decisions completely. Your clients will never know about Fonimo being the backend force for you.</div>
                                </div>
                                <div className="partnership-grid-item agent highlight">
                                    <div>We will support customer relationships. Our team will manage billing and lead nurturing completely by keeping you as involved as you want. We own the process.</div>
                                </div>
                                <div className="partnership-grid-item" style={{ background: '#f0f8ff', color: '#000' }}>
                                    <div className="partnership-grid-row-title">End User Technical Support</div>
                                </div>
                                <div className="partnership-grid-item white-label">
                                    <div>You own and define the processes to provide complete customer support. The Fonimo team will stay at the backend to provide the required technical help.</div>
                                </div>
                                <div className="partnership-grid-item agent highlight">
                                    <div>Our team will manage the process and offer the required technical and nontechnical customer support.</div>
                                </div>
                                <div className="partnership-grid-item" style={{ background: '#f0f8ff', color: '#000', borderBottomLeftRadius:"26px" }}>
                                    <div className="partnership-grid-row-title">Payment and Revenue</div>
                                </div>
                                <div className="partnership-grid-item white-label">
                                    <div>You pay the predefined fee to use Fonimo, a white label app, and other services. You add your margins to clients and generate revenue and ROI as per your own caliber and strategies.</div>
                                </div>
                                <div className="partnership-grid-item agent highlight radius-bottom"  style={{ borderBottomRightRadius: '26px' }}>
                                    <div>We define pricing structure and manage customer billing. You will earn through predefined commissions on a recurring basis and that also for a lifetime.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="services-area position-relative py-5"  style={{ width: '100%', minHeight: '650px' }}>
        <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-8">
                        <div className="section-title text-center mb-5">
                            <h2 className="title">Let’s Get Started in Three Simple Steps</h2>
                            <p className='mt-4'>Start the journey of assured earning by selecting your preferred partnership model. No commitments yet guaranteed earnings are available for all our partners. No obligation partnership assures stress-free earning for our partners.</p>
                        </div>
                    </div>
                </div>
                <div className="row services-item-wrap justify-content-center">

                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon step-number">
                                    <span>Step 1</span>    
                                </div>
                                <div className="services-content">
                                    <h4>Initiate the Call</h4>
                                    <p>Take the first step for this long term relationship by hopping on the discussion call. Schedule the call as per your availability.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon step-number">
                                    <span>Step 2</span>    
                                </div>
                                <div className="services-content">
                                    <h4>Start Selling</h4>
                                    <p>Use our marketing and sales strategies along with editable marketing material to start selling to initiate the earning process.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon step-number">
                                    <span>Step 3</span>    
                                </div>
                                <div className="services-content">
                                    <h4>Enjoy Recurring Earnings</h4>
                                    <p>Keep selling and earning with recurring commission models for a lifetime. The best Softphone app will attract clients automatically.</p>
                                </div>
                            </div>
                        </div>

                    <div className="service-line-shape one"></div>
                    <div className="service-line-shape two"></div>
                    <div className="service-line-shape three"></div>
                    <div className="service-line-shape four"></div>
                </div>
        </div>
        <div className="service-shape one"><img width={135} height={135} src="img/services_shape01.png" alt="" /></div>
        <div className="service-shape two"><img width={43} height={43} src="img/services_shape02.png" alt="" /></div>
    </section>

    <section className='cta-block'>
        <div className="container py-5">
            <div className='row py-2 align-items-center justify-content-center'>
                <div className="col-lg-6 col-12">
                    <h2 className="text-white pb-1 animated slideInDown">Discuss Your Questions!</h2>
                    <h3 className="text-white mb-4 animated slideInDown">Initiate the Detailed Discussion.</h3>
                    <a href="/contact-us" target="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Schedule a Call</a>
                </div>
                <div className='col-lg-4 col-12'>
                    <figure>
                        <img src='/img/Partner_2.webp' alt='CTA' />
                    </figure>
                </div>
            </div>
        </div>
    </section>

    <section className="why-choose-block">
        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-5">Frequently Asked Questions</h3>
                </div>
                <div className="row gy-5 gx-4 justify-content-center">
                <div className="faq-wrap">
                        <div className="faq-set">
                            <h4 className='active'>1. Do you provide white labeling to partners? <i className="fas fa-angle-down"></i></h4>
                            <div className="content" style={{display: "block"}}>
                                <p>Yes, we have two models for partnership. One is driven by the partner and we work as a backend support team. This model provides a white labeling app at a nominal cost for the white labeling process.</p>
                            </div>
                        </div>
                        <div className="faq-set">
                            <h4>2. What happens once I assign the lead? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>Once you refer a lead to our sales team, they will manage the discussions, demos, billing, and everything required to convert and retain the clients. You can stay involved or simply stay updated about the lead status. Once the lead is converted, you will get earning as per the predefined commission structure for a lifetime.</p>
                            </div>
                        </div>
                        <div className="faq-set">
                            <h4>3. What is the process to become a partner or reseller of Fonimo? <i className="fas fa-angle-down"></i></h4>
                            <div className="content">
                                <p>It is very straightforward to become our partner with no prior commitments or targets to achieve. All you need to do is connect with our team and they will explain the whole process and answer all your queries.</p>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Partners