import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../components/common/Misc.js';
import Contact from '../components/home/Contact.js';
import Meta from '../components/common/Meta.js';
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Home = () => {

  const [response, setResponse] = useState(null);
  const [levelTwoResponse, setLevelTwoResponse] = useState(null);

  useEffect(() => {
      const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
      
      fetch(`${BackendURL}/api/pages/home?populate[Blocks][populate]=*&populate[meta][populate]=*&populate[Featured_Image]=*`, { headers })
          .then(response => response.json())
          .then(data => setResponse(data.data));
      fetch(`${BackendURL}/api/pages/home?populate[0]=Blocks.Card.image`, { headers })
          .then(response => response.json())
          .then(data => setLevelTwoResponse(data.data));     
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4000,
};

  return (
    <>
    <main>
      {response && response.attributes.meta ?
        <Meta value={response.attributes} canonical="https://fonimo.app/" />
      : ""}
      <section className='hero-block' id="home">
            <div className="container px-lg-5">
                <div className="row">
                    <div className="col-lg-5 text-center text-lg-start pe-lg-5">
                        {response && response.attributes.Blocks[0].header.heading ? 
                            <h1 className="text-white mb-4">{response.attributes.Blocks[0].header.heading}</h1>
                        : ""}
                        {response && response.attributes.Blocks[0].header.content ? 
                            <p className="text-white pb-3">{response.attributes.Blocks[0].header.content[0].children[0].text}</p>
                        : ""}
                        {response && response.attributes.Blocks[0].button ? 
                            response.attributes.Blocks[0].button.map(({url,label,target},index) => (
                                <a key={index} href={url} target={`${target ? target : ""}`} className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated">{label}</a>
                            ))
                        : ""}
                    </div>
                    <div className="col-lg-7 d-flex justify-content-center justify-content-lg-end">
                        <div className={`slider-wr`} style={{ width: '100%', minHeight: '500px' }}>
                        <img width={223}  alt="Fonimo" src="/img/Chat.webp" />
                    {/* {response && response.attributes.Blocks[0].image ?
                        <Slider {...settings}>
                            {response.attributes.Blocks[0].image.data.map(({attributes},index) => (
                                <img width={223} height={470} key={index} alt={attributes.name.replace(/\.[^/.]+$/, "")} src={BackendURL+attributes.url} />
                            ))}
                        </Slider>
                    : ""} */}
                        </div>
                    </div>
                </div>
            </div>
      </section>
      <section className='about-block' style={{ width: '100%', minHeight: '500px' }}>
        <div  id="about" className="container py-5 px-lg-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    {response && response.attributes.Blocks[1].subHeading ? 
                        <h5 className="text-primary-gradient fw-medium">{response.attributes.Blocks[1].subHeading}</h5>
                    : ""}
                    {response && response.attributes.Blocks[1].heading ? 
                        <h2 className="mb-4">{response.attributes.Blocks[1].heading}</h2>
                    : ""}
                    {response && response.attributes.Blocks[1].content.length > 1 ? 
                        response.attributes.Blocks[1].content.map(({children}, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: children[0].text }}></p>
                        ))
                    : ""}
                    {/* {response && response.attributes.Blocks[1].content ? 
                        <p dangerouslySetInnerHTML={{ __html: response.attributes.Blocks[1].header.content[0].children[0].text}}></p> 
                    : ""} */}
                    
                </div>
                <div className="col-lg-6">
                    {response && response.attributes.Featured_Image ? 
                        <img className='img-fluid' width={588} height={407} alt={response.attributes.Featured_Image.data.attributes.name.replace(/\.[^/.]+$/, "")} src={BackendURL+response.attributes.Featured_Image.data.attributes.url}  />
                    : ""}
                </div>
            </div>
        </div>
      </section>
      <section className='features-block' id="feature" style={{ width: '100%', minHeight: '750px', maxHeight: '1800px' }}>
          <div className="container-xxl py-5">
              <div className="container px-lg-5">
                  <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                      {levelTwoResponse && levelTwoResponse.attributes.Blocks[2].sub_heading ? 
                          <h5 className="text-primary-gradient fw-medium">{levelTwoResponse.attributes.Blocks[2].sub_heading}</h5>
                      : ""}
                      {levelTwoResponse && levelTwoResponse.attributes.Blocks[2].heading ? 
                          <h2 className="mb-5">{levelTwoResponse.attributes.Blocks[2].heading}</h2>
                      : ""}
                  </div>
                  {levelTwoResponse && levelTwoResponse.attributes.Blocks[2].Card ? 
                      <div className="row g-4">
                          {levelTwoResponse.attributes.Blocks[2].Card.map(({id,text,title,image}) => (
                              <div key={id} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                  <div className="feature-item bg-light rounded p-4">
                                      <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                          <img width={40} height={40} style={{width: "40px"}} title={image.data.attributes.name.replace(/\.[^/.]+$/, "")} className="img-fluid" src={BackendURL+image.data.attributes.url} alt={image.data.attributes.name.replace(/\.[^/.]+$/, "")} />
                                      </div>
                                      {title ? 
                                      <h5 className="mb-3">{title}</h5>
                                      : ""}
                                      {text ? 
                                      <p className="m-0">{text}</p>
                                      : ""}
                                  </div>
                              </div>
                          ))}
                      </div>
                  : ""}
              </div>
          </div>
      </section>
      <section className='cta-block'>
          <div className="container-fluid py-5">
              <div className="container">
                  <div className="col-12 text-center">
                    {response && response.attributes.Blocks[3].title? 
                      <h3 className="text-white pb-5 animated slideInDown">{response.attributes.Blocks[3].title}</h3>
                    :""}
                    {response && response.attributes.Blocks[3].link.length !== 0 ? 
                      <a href={response.attributes.Blocks[3].link[0].url} target={`${response.attributes.Blocks[3].link[0].target ? response.attributes.Blocks[3].link[0].target : ""}`} className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">{response.attributes.Blocks[3].link[0].label}</a>
                    : ""}
                  </div>
              </div>
          </div>
      </section>
      <section id='benefits' className="services-area position-relative"  style={{ width: '100%', minHeight: '650px' }}>
        <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="section-title text-center mb-5">
                        {levelTwoResponse && levelTwoResponse.attributes.Blocks[4].heading ? 
                            <h2 className="title">{levelTwoResponse.attributes.Blocks[4].heading}</h2>
                        : ""}
                        </div>
                    </div>
                </div>
            {levelTwoResponse && levelTwoResponse.attributes.Blocks[4].Card ? 
                <div className="row services-item-wrap justify-content-center">
                    {levelTwoResponse.attributes.Blocks[4].Card.map(({text,title,image},index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon">
                                    <img width={60} height={60} style={{width: "60px"}} title={image.data.attributes.name.replace(/\.[^/.]+$/, "")} className="img-fluid" src={BackendURL+image.data.attributes.url} alt={image.data.attributes.name.replace(/\.[^/.]+$/, "")} />
                                </div>
                                <div className="services-content">
                                    <h4>{title}</h4>
                                    <p>{text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="service-line-shape one"></div>
                    <div className="service-line-shape two"></div>
                    <div className="service-line-shape three"></div>
                    <div className="service-line-shape four"></div>
                </div>
            : ""}
        </div>
        <div className="service-shape one"><img width={135} height={135} src="img/services_shape01.png" alt="" /></div>
        <div className="service-shape two"><img width={43} height={43} src="img/services_shape02.png" alt="" /></div>
      </section>
      <section className='integrations-block' id="integration" style={{ width: '100%', minHeight: '550px' }}>
        <div className="container py-5 px-lg-4">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                {levelTwoResponse && levelTwoResponse.attributes.Blocks[5].sub_heading ? 
                    <h5 className="text-primary-gradient fw-medium">{levelTwoResponse.attributes.Blocks[5].sub_heading}</h5>
                : ""}
                {levelTwoResponse && levelTwoResponse.attributes.Blocks[5].heading ? 
                    <h2 className="mb-5">{levelTwoResponse.attributes.Blocks[5].heading}</h2>
                : ""}
            </div>
            {levelTwoResponse && levelTwoResponse.attributes.Blocks[5].Card ? 
                <div className="row g-4">
                    {levelTwoResponse.attributes.Blocks[5].Card.map(({text,title,image},index) => (
                    <div key={index} className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded text-center p-4">
                            <img width={60} height={60} style={{width: "60px"}} title={image.data.attributes.name.replace(/\.[^/.]+$/, "")} className="img-fluid" src={BackendURL+image.data.attributes.url} alt={image.data.attributes.name.replace(/\.[^/.]+$/, "")} />
                            <h4 className="mb-3">{title}</h4>
                            <p className="m-0">{text}</p>
                        </div>
                    </div>
                    ))}
                </div>
            : ""}
        </div>
      </section>
      <section className='interface-block'>
        <div className="container py-5 px-lg-5">
            <div className='row g-5 text-center mb-4'>
                <div className='col-12'>
                    {response && response.attributes.Blocks[6].header.subHeading ? 
                        <h5 className="text-primary-gradient fw-medium">{response.attributes.Blocks[6].header.subHeading}</h5>
                    : ""}
                    {response && response.attributes.Blocks[6].header.heading ? 
                        <h2 className="mb-4">{response.attributes.Blocks[6].header.heading}</h2>
                    : ""}
                    <p className='mb-3'>
                    Our WebRTC Softphone application is developed with the aim of simplifying and augmenting mobility and calling experience. With modern and user friendly design, along with the convenience of one-click calling functionality, you will enjoy proficiency and the art of making calls effortlessly.                        
                    </p>
                </div>
            </div>
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    {response && response.attributes.Blocks[6].header.content.length > 1 ? 
                        response.attributes.Blocks[6].header.content.map(({children},index) => (
                            <p key={index} className="mb-3" dangerouslySetInnerHTML={{ __html: children[0].text}}></p>
                            
                            ))
                    : "" }
                    {/* {response && response.attributes.Blocks[6].header.content? 
                        <p className="mb-3" dangerouslySetInnerHTML={{ __html: response.attributes.Blocks[6].header.content[0].children[0].text}}></p>
                    : ""} */}
                    {response && response.attributes.Blocks[6].button[0].url ? 
                        <a href={response.attributes.Blocks[6].button[0].url} className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">{response.attributes.Blocks[6].button[0].label}</a>
                    : ""}
                </div>
                <div className="col-lg-6 justify-content-center justify-content-lg-end">
                    <div className={`desktop-slider-wr`}>
                        {response && response.attributes.Blocks[6].image ? 
                        <Slider {...settings}>
                            <img alt="Calls" src="/img/Calls.webp" />
                            <img alt="Contacts" src="/img/Contacts.webp" />
                            <img alt="User_Experience" src="/img/User_Experience2.webp" />
                            <img alt="User_Experience" src="/img/User_Experience3.webp" />
                        </Slider>
                        : "" }
                        </div>
                    {/* {response && response.attributes.Blocks[7].image ? 
                        <LazyLoadImage wrapperClassName="img-fluid wow fadeInUp" alt={response.attributes.Blocks[7].image.data[0].attributes.name.replace(/\.[^/.]+$/, "")} src={BackendURL+response.attributes.Blocks[7].image.data[0].attributes.url} />
                    : ""} */}
                </div>
            </div>
        </div>
      </section>
      <section className="download-block">
        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-4 d-flex justify-content-center justify-content-lg-start wow fadeInUp" data-wow-delay="0.3s">
                        <div className={`slider-wr`}>
                        {response && response.attributes.Blocks[6].image ? 
                        <Slider {...settings}>
                                {response.attributes.Blocks[6].image.data.map(({attributes},index) => (
                                    <img width={223} height={470} key={index} alt={attributes.name.replace(/\.[^/.]+$/, "")} src={BackendURL+attributes.url} />
                                ))}
                        </Slider>
                        : "" }
                        </div>
                    </div>
                    <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.3s">
                        {response && response.attributes.Blocks[7].header.subHeading ? 
                            <h5 className="text-primary-gradient fw-medium">{response.attributes.Blocks[7].header.subHeading}</h5>
                        : ""}
                        {response && response.attributes.Blocks[7].header.heading ? 
                            <h2 className="mb-4">{response.attributes.Blocks[7].header.heading}</h2>
                        : ""}
                        {response && response.attributes.Blocks[7].header.content ? 
                            <p className="mb-4">{response.attributes.Blocks[7].header.content[0].children[0].text}</p>
                        : ""}
                        {response && response.attributes.Blocks[7].button ? 
                        <div className="row g-4">
                            {response.attributes.Blocks[7].button.map(({label,url,target},index) => (
                                <div key={index} className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                    <a href={url} target={`${target ? target : ""}`} className="d-flex download-btn bg-primary-gradient rounded py-3 px-4">
                                        <i className={`fab fa-${label === "App Store" ? "apple" : "android"} fa-3x text-white flex-shrink-0`}></i>
                                        <div className="ms-3">
                                            <p className="text-white mb-0">Available On</p>
                                            <h5 className="text-white mb-0">{label}</h5>
                                        </div>
                                    </a>
                                </div>
                            ))}
                            {/* <div className="modal fade" id="exampleModalCenter" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" style={{display:"none"}} aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <h3 className='text-center'>Coming Soon!</h3>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        : ""}
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Contact/>
      {/* {response && response.attributes.meta ?
      <WhyFonimo value={response.attributes.Blocks[8]}/>
      : ""} */}
    </main>
    </>
  )

}

export default Home