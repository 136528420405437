import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';


const Qrcode = () => {

    const [formData, setFormData] = useState({ username: '', password: '', domain: ''});
    const [qrValue, setQrValue] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { username, password, domain } = formData;
        const qrData = `${username},${password},${domain}`;
        setQrValue(qrData);
    };

    useEffect(() => {
        document.body.classList.add('qrcode-page');

        return () => {
            document.body.classList.remove('qrcode-page');
        };
    }, []);

  return (
    <>
    <section className="qrcode_generator_blk" style={{padding:"150px 0 0"}}>
        <div className='container'>
            <div className='row qrcode_inner_wr justify-content-center'>
                <div className='col-lg-5 col-10 text-center mb'>
                    <h1>QR Code Generator</h1>
                    <form onSubmit={handleSubmit}>
                        <div className='row g-3'>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input className="form-control" type="text" name="username" placeholder='Username' value={formData.username} onChange={handleChange} required />
                                    <label>Username</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input className="form-control" placeholder="Password" type="password" name="password" value={formData.password} onChange={handleChange} required />
                                    <label>Password</label>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-floating">
                                    <input className="form-control" placeholder="Domain" type="text" name="domain" value={formData.domain} onChange={handleChange} required />
                                    <label>Domain</label>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 text-center mt-3">
                            <button className={`btn btn-primary-gradient rounded-pill py-3 px-5`} type="submit">Generate QR Code</button>
                        </div>
                    </form>
                </div>
                <div className='col-lg-5 col-10 text-center'>
                {qrValue && (
                    <div className='qrcode_wr'>
                    <h2>QR Code:</h2>
                    <QRCode value={qrValue} />
                    </div>
                )}
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Qrcode