import swal from 'sweetalert';
const SentMail = async (data, api, e) => {

    try {
        const response = await fetch(process.env.REACT_APP_PRODUCTION_BACKEND_API_URL + api, {
        method: "POST",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_PRODUCTION_BACKEND_API_TOKEN}`
        },
        body: data
        });
        
        if (response.ok) {
            swal({
                text: "Form submitted successfully!",
                icon: "success",
            }).then( ok => {
                e.target.reset();
                return true;
            })
        } else {
            swal({
                text: "Something went wrong. Form not submitted.",
                icon: "error",
            }).then( ok => {
                e.target.reset();
                return true;
            })   
        }

    } catch (error) {
        swal({
            text: "Something went wrong. Please try again later.",
            icon: "error",
        }).then( ok => {
            e.target.reset();
            return true;
        })
        console.error('Error submitting form:', error);
    }
}

export default SentMail;