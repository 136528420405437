import React from 'react'
import ContactForm from '../components/common/ContactForm.js';
import Meta from '../components/common/Meta'

const metaData = {
    meta : {
        meta_title: "Discover the Future of Unified Communications at UCX Expo 2024",
        meta_desc: "Join us at UCX Expo 2024 to explore cutting-edge unified communications technologies. Network with industry leaders and stay ahead of future trends.",
    }
}

const Expo = () => {
    document.body.classList.add('expo-page');
  return (
    <>
    <Meta value={metaData} canonical="https://fonimo.app/ucx-expo" />
    <section className='ucx-expo-blk'>
        <div className='ucx-inner-wr'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-sm-12 p-0 px-5'>
                        <figure>
                            <img src='/img/expo.png' title='ITEXPO 2025' alt='ITEXPO 2025' />
                        </figure>
                    </div>
                    <div className='col-lg-6 col-sm-12 p-0 px-5'>
                        <h1 className='text-center mb-3'>Book a Meeting</h1>
                        <ContactForm class="expo-form"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='team-blk'>
        <div className='container'>
            <div className='team-inner-wr'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-lg-10 text-center'>
                        <h2>Meet Our Expert</h2>
                        <p><span style={{fontSize:"24px"}}>"</span>I’m excited to connect with you! Let’s dive into the world of innovation at Inextrix Technologies together. From AI-powered communication to custom VoIP and WebRTC solutions, I’ve got insights to share and ideas to explore. Let’s create something amazing!<span style={{fontSize:"24px"}}>"</span></p>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-lg-5 col-md-6 col-sm-8'>
                                <div className='team-card'>
                                    <figure>
                                        <img style={{maxWidth:"220px",margin:"0 auto"}} src='/img/Arpit-Modi.png' alt='Arpit Modi'/>
                                    </figure>
                                    <h3>Arpit Modi</h3>
                                    <h5>Co-Founder & CEO</h5>
                                    <a style={{fontSize:"28px"}} className="linkedin-url" href="https://www.linkedin.com/in/arpit-modi-a174b710/" target="_blank">
                                        <i aria-hidden="true" className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Expo