import React from 'react'

const PageNotFound = () => {
  return (
    <>
    <section className='error-blk'>
        <div className='blogs-inner-wr hero-header'>
            <div className='container px-lg-5'>
                <div className='row g-5 justify-content-center'>
                    <div className='col-lg-10 text-center '>
                        <h1 className='text-white mb-4 animated slideInDown'>404</h1>
                        <h3 className='text-white '>Page Not Found</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PageNotFound